import { graphql } from "gatsby";
import React from "react";
import tw from "twin.macro";
import {
  PageLayout,
  Exemptions,
  ServicesCard,
  CTA,
  BlogContentBlock,
} from "../components";

const EyeExams: React.FC = ({
  data: {
    strapiDsrEyeExaminations: {
      title,
      intro,
      subtitle,
      mainContent,
      servicesContent,
      services,
    },
  },
}) => {
  return (
    <PageLayout
      title={title}
      subtitle={subtitle}
      intro={intro}
      content={mainContent}
      flourish={2}
    >
      <Exemptions />
      <section>
        <div tw="bg-dsrTeal-900 overflow-hidden py-16 relative">
          <div tw="relative px-4 sm:px-6 lg:px-8">
            <div tw="mt-6 prose prose-lg text-gray-500 mx-auto">
              <h2>
                <span tw="text-white">Services</span>
              </h2>
              <BlogContentBlock textWhite content={servicesContent} />
              <div tw="space-y-9 mt-4">
                {services.map((a) => (
                  <ServicesCard key={a.title} {...a} />
                ))}
                <div tw="bg-white shadow overflow-hidden rounded-lg">
                  <div tw="px-4 py-3 sm:px-6 mt-2 mb-5">
                    <h3 tw="text-lg font-medium text-gray-900 my-3!">
                      MECS – Minor Eye Conditions Service
                    </h3>
                    <div css={[tw`max-w-2xl text-sm text-gray-500 -mt-2!`]}>
                      (only available at Matlock Opticians)
                      <br />
                      <br />
                      NHS referral service for eye related problems that are not
                      covered by a routine eye examination such as:
                    </div>
                  </div>
                  <div tw="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl tw="sm:divide-y sm:divide-gray-200">
                      <ul tw="px-6">
                        <li tw="font-medium text-gray-900 text-sm my-2!">
                          Red eye
                        </li>
                        <li tw="font-medium text-gray-900 text-sm my-2!">
                          Dry eye
                        </li>
                        <li tw="font-medium text-gray-900 text-sm my-2!">
                          Painful, irritated eyes
                        </li>
                        <li tw="font-medium text-gray-900 text-sm my-2!">
                          Flashes and floaters
                        </li>
                        <li tw="font-medium text-gray-900 text-sm my-2!">
                          Blurred vision
                        </li>
                      </ul>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA />
    </PageLayout>
  );
};

export default EyeExams;

export const query = graphql`
  query eyeExamsPageQuery {
    strapiDsrEyeExaminations {
      mainContent
      servicesContent
      subtitle
      intro
      title
      services {
        description
        header
        items {
          description
          name
          pricePounds
        }
      }
    }
  }
`;
